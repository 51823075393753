
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CartItem, { recalcCartItem } from "@/components/Cart/CartItem";
import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import { formatter } from "@/utils/currencyUtils";
import DatePicker from "@/components/SiteBook/DatePicker.vue";
import Property from "@/models/Property";
import { DayOfWeek, getDayOfWeekString } from "@/models/DayOfWeek";
import { getAvailabilityForNextYear, GridModel } from "@/models/GridModel";
import { Rate } from "../Cloud/Rates/Rate";
import { Unit, UnitTypes } from "@/components/Cloud/Units/Unit";
import { getTimeSlots, TimeSlot } from "../Cloud/TimeSlots/TimeSlot";
import {
  getAvailableRates,
  getUnit,
  availableDates,
} from "../Cloud/Units/UnitService";
import { getProperty } from "../Cloud/Settings/PropertyService";
import { Guid } from "@/utils/Guid";
import { AddOn, getAddOns } from "../Cloud/ChargeItems/AddOn";
import {
  DeliveryOption,
  getDeliveryOption,
  getDeliveryOptions,
} from "../Cloud/DeliveryOptions/DeliveryOption";
import {
  getCustomQuestion,
  getCustomQuestions,
} from "../Cloud/CustomQuestions/CustomQuestion";
import CartSummary from "../Cart/CartSummary.vue";
import StyleImage from "./StyleImage.vue";
import { Cart } from "../Cart/Cart";

const cartStore = namespace("CartStore");
const AvailabilityParametersStore = namespace("AvailabilityParametersStore");

@Component({
  components: {
    DatePicker,
    CartSummary,
    StyleImage,
  },
  filters: {
    defaultDate(val: any) {
      return val ? val.toLocaleString() : "";
    },
  },
})
class Book extends Vue {
  @Prop({ default: "false" }) public useDatesFromStore!: string;

  private property = new Property();
  private cartItem: CartItem = new CartItem();
  private rates: Array<Rate> = [];
  private selectedRate = new Rate();
  public unit: Unit = new Unit();
  private errors = new Array<string>();
  public isNew = true;
  private busy = false;
  private loaded = false;
  private availableDates = new Array<Date>();
  private cartItemKey = Guid.newGuid();
  private agreeToTerms = false;
  private showTermsModal = false;
  private cart = new Cart();

  formatDate(dateFor: string) {
    return dateFor.split("T")[0];
  }

  getThumb() {
    return this.unit.thumb;
  }

  async mounted() {
    this.loaded = false;
    this.busy = true;

    if (this.$route.query.cartItemGuid) {
      this.cartItem = await this.getCartItem(
        this.$route.query.cartItemGuid.toString()
      );
      if (this.cartItem) {
        this.isNew = false;
      } else {
        this.isNew = true;
      }
    }

    if (this.isNew) {
      if (this.$route.params.unit) {
        this.cartItem.unitGuid = this.$route.params.unit.toString();
      } else if (this.$route.query.unit) {
        //legacy
        this.cartItem.unitGuid = this.$route.query.unit.toString();
      }
    }

    this.unit = await getUnit(this.cartItem.unitGuid);
    this.property = await getProperty(this.unit.propertyGuid);
    localStorage.setItem("propertyGuid", this.unit.propertyGuid);

    if (this.isNew) {
      // get all objects from the avail params or route
      this.mountNewCartItem();
    } else {
      // get all objects from the cart item
      this.mountExistingCartItem();
    }

    this.cartItem.image = this.unit && this.unit.thumb ? this.unit.thumb : "";
    if (this.unitIsTimeSlot()) {
      await Promise.all([
        (this.rates = await getAvailableRates(this.cartItem)),
        (this.availableDates = await availableDates(this.unit.unitGuid)),
      ]);
      if (this.rates && this.rates.length > 0) {
        let rate = this.rates.find((r) => r.rateGuid == this.cartItem.rateGuid);
        if (rate) {
          if (rate.disabled) {
            this.selectedRate == null;
            this.cartItem.rateGuid = undefined;
          } else {
            this.selectedRate = rate;
          }
        } else if (this.rates[0] && this.rates[0].disabled == false) {
          this.selectedRate = this.rates[0];
          this.cartItem.rateGuid = this.selectedRate.rateGuid;
        }
      }
      if (this.cartItem.rateGuid && this.selectedRate) {
        this.selectedRate.timeSlots = await getTimeSlots(
          this.cartItem.rateGuid,
          this.cartItem.unitGuid,
          this.cartItem.inDateTime
        );
      }
    } else {
      let gridModel: GridModel;
      gridModel = await getAvailabilityForNextYear(
        this.property.propertyGuid,
        this.unit.unitGuid
      );
      this.rates = await getAvailableRates(this.cartItem);

      if (gridModel.gridUnits && gridModel.gridUnits.length > 0) {
        let gu = gridModel.gridUnits[0];
        if (gu && gu.gridDays) {
          this.unit.gridDays = gu.gridDays;

          //triggers a calendar refresh
          this.cartItem.inDateTime = new Date(
            this.cartItem.inDateTime
          ).addMinutes(1);
        }
      }
    }

    this.loaded = true;
    await this.recalc();
    this.busy = false;
  }

  async mountExistingCartItem() {
    this.agreeToTerms = true;
    await this.validate();
  }

  async mountNewCartItem() {
    this.cartItem.unitGuid = this.unit.unitGuid;
    this.cartItem.propertyGuid = this.property.propertyGuid;
    this.cartItem.unitName = this.unit.unitName;
    this.cartItem.currencyCode = this.property.currencyCode;

    this.cartItem.unitType = this.unit.unitType;

    this.getDefaultAvailParams();
    this.cartItem.inDateTime = new Date().addDays(7);
    this.cartItem.outDateTime = new Date().addDays(9);

    if (this.$route.query.adults) {
      this.availParams.adults = Number(this.$route.query.adults.toString());
      this.cartItem.adults = this.availParams.adults;
    }
    if (this.$route.query.children) {
      this.availParams.children = Number(this.$route.query.children.toString());
      this.availParams.children = this.availParams.children;
    }
    if (this.$route.query.date) {
      this.availParams.arrivalDate = new Date(
        this.$route.query.date.toString() + " 00:00:00"
      );
      this.cartItem.inDateTime = this.availParams.arrivalDate;
    }
    if (this.$route.query.length) {
      let length = Number(this.$route.query.length.toString());
      this.cartItem.outDateTime = new Date(this.cartItem.inDateTime).addDays(
        length
      );
      this.availParams.departureDate = this.cartItem.outDateTime;
    }
    this.cartItem.adults = Number(this.availParams.adults);
    this.cartItem.children = Number(this.availParams.children);
    this.cartItem.nights = Math.abs(
      new Date(this.cartItem.inDateTime).daysDiff(this.cartItem.outDateTime)
    );

    if (this.unitIsTimeSlot()) {
      await Promise.all([
        (this.cartItem.addOns = await getAddOns(this.unit.unitGuid)),
        (this.cartItem.deliveryOptions = await getDeliveryOptions(
          this.unit.unitGuid
        )),
        (this.cartItem.questionnaire = await getCustomQuestions(
          this.unit.unitGuid
        )),
      ]);
    }
  }

  showTerms() {
    this.showTermsModal = true;
  }

  async addOnChanged(addOn: AddOn) {
    this.recalc();
  }

  dateDisabled(date: any) {
    date = new Date(date);
    const available = this.availableDates.some((d) =>
      new Date(date).areSameDay(d)
    );
    let tomorrow = new Date().addDays(1);
    return !available || date <= tomorrow;
  }

  navigateToBreadcrumbSearchResults() {
    this.$router.push({
      name: "search",
      query: { ...this.$router.currentRoute.query },
    });
  }

  getAddOnLabel(addOn: AddOn) {
    return addOn.description + " - " + this.formatCurrency(addOn.price);
  }

  async slotClicked(slot: TimeSlot, rate: Rate) {
    if (this.cartItem.quantity > slot.available) {
      this.cartItem.quantity = slot.available;
    }
    this.busy = true;
    this.cartItem.timeSlotGuid = slot.timeSlotGuid;
    if (slot.startDate) {
      this.cartItem.inDateTime = slot.startDate;
    }
    if (slot.endDate) {
      this.cartItem.outDateTime = slot.endDate;
    }
    console.log('cart item out date time = ' + this.cartItem.outDateTime);
    this.recalc();
    await this.validate();
    this.busy = false;
  }

  deliveryOptionClicked(opt: DeliveryOption) {
    opt.checked = !opt.checked;
  }

  isRateSelected() {
    let isnothing = this.selectedRate == undefined || !this.selectedRate;
    if (isnothing) {
      return false;
    } else {
      return true;
    }
  }

  timeSlotsPresent() {
    if (!this.isRateSelected()) {
      return false;
    }
    if (this.selectedRate && this.selectedRate.timeSlots == undefined) {
      return false;
    }
    if (this.selectedRate && this.selectedRate.timeSlots.length == 0) {
      return false;
    }
    return true;
  }

  async rateClicked(rateSelected: Rate) {
    this.busy = true;
    this.clearPrice();
    if (this.cartItem.rateGuid != rateSelected.rateGuid) {
      this.cartItem.timeSlotGuid = undefined;
    }
    this.cartItem.rateGuid = rateSelected.rateGuid;
    this.selectedRate = rateSelected;
    this.selectedRate.timeSlots = await getTimeSlots(
      rateSelected.rateGuid,
      this.unit.unitGuid,
      this.cartItem.inDateTime
    );
    if (this.selectedRate && this.selectedRate.timeSlots && this.selectedRate.timeSlots.length == 1) {
      // only one timeslot so just select it
      let slot = this.selectedRate.timeSlots[0];
      if (slot.available > 0 && slot.disabled == false) {
        await this.slotClicked(slot, this.selectedRate);
      }
    }
    this.recalc();
    this.busy = false;
  }

  // for timeslot rates
  async quantityChanged(qty: number) {
    this.cartItem.quantity = qty;
    if (this.cartItem.timeSlotGuid) {
      let slot = this.selectedRate.timeSlots.find(s => s.timeSlotGuid == this.cartItem.timeSlotGuid);
      if (slot) {
        if (this.cartItem.quantity > slot.available) {
          // either reset the form or just force the quantity to less
          this.cartItem.quantity = slot.available;
        }
      }
      await this.recalc();
    }
  }

  getRateLabel(rate: Rate) {
    let result = "";
    if (rate) {
      result = rate.name;
    }
    if (rate.disabled) {
      result += " Unavailable";
    }
    return result;
  }

  getTimeSlotLabel(slot: TimeSlot, rate: Rate) {
    let result = ``;
    if (slot) {
      if (slot.startDate && slot.endDate) {
        // if it's a timeslot greater than a day do this
        let start = new Date(slot.startDate).monthDay();
        let end = new Date(slot.endDate).monthDay();
        let time = new Date(slot.startDate).time(true);
        let endTime = new Date(slot.endDate).time(true);
        let isHourly = new Date(slot.startDate).areSameDay(slot.endDate);
        let available = slot.disabled
          ? " Unavailable"
          : `${slot.available} Available`;
        if (isHourly) {
          //`9:00 AM - 1:00 PM (3 Available)`
          result = `${time} - ${endTime} (${available})`;
        } else {
          //`August 13, 8:00 AM - August 15, 6:00 PM (1 Available)`;
          result = `${start}, ${time} - ${end}, ${endTime} (${available})`;
        }
      }
    }

    return result;
  }

  get adultCount() {
    return this.availParams.peopleCount1;
  }

  set adultCount(value: number) {
    if (!this.loaded) {
      return;
    }
    this.$route.query.adults = value.toString();
    this.cartItem.adults = Number(value);
    this.availParams.peopleCount1 = Number(value);
    this.set(this.availParams);
    this.recalc();
  }

  get childCount() {
    return this.availParams.peopleCount2;
  }
  set childCount(value: number) {
    if (!this.loaded) {
      return;
    }
    this.cartItem.children = Number(value);
    this.availParams.peopleCount2 = Number(value);
    this.set(this.availParams);
    this.recalc();
  }

  //legacy
  datePicked(first: Date, second: Date) {
    this.cartItem.inDateTime = new Date(first).addTime(
      new Date(this.property.defaultArrivalTime).toTimeString()
    );
    this.cartItem.outDateTime = new Date(second).addTime(
      new Date(this.property.defaultDepartureTime).toTimeString()
    );
    this.recalc();
  }

  // timeslot single date calendar
  async calendarPicked(datePicked: Date) {
    this.busy = true;
    if (this.loaded) {
      let d = new Date(datePicked);
      this.cartItem.inDateTime = d;
      this.rates = await getAvailableRates(this.cartItem);
      this.cartItem.rateGuid = undefined;
      this.selectedRate = undefined;
      this.cartItem.timeSlotGuid = undefined;
      this.clearPrice();

      if (this.rates && this.rates[0] && this.rates[0].disabled == false) {
        this.selectedRate = this.rates[0];
        this.cartItem.rateGuid = this.selectedRate.rateGuid;
      }
      if (this.cartItem.rateGuid && this.selectedRate) {
        this.selectedRate.timeSlots = await getTimeSlots(
          this.cartItem.rateGuid,
          this.cartItem.unitGuid,
          this.cartItem.inDateTime
        );
        if (this.selectedRate && this.selectedRate.timeSlots && this.selectedRate.timeSlots.length == 1) {
          // only one timeslot so just select it
          let slot = this.selectedRate.timeSlots[0];
          if (slot.available > 0 && slot.disabled == false) {
            await this.slotClicked(slot, this.selectedRate);

          }

        }
      }
    }
    this.busy = false;
  }

  loadAvailParamsFromCartItem() {
    this.availParams.arrivalDate = this.cartItem.inDateTime;
    this.availParams.departureDate = this.cartItem.outDateTime;
    this.availParams.adults = this.cartItem.adults;
    this.availParams.children = this.cartItem.children;
    this.availParams.unitGuid = this.cartItem.unitGuid;
    this.availParams.defaultMode = false;
    this.set(this.availParams);
  }

  unitIsTimeSlot() {
    return (
      this.unit.unitType == UnitTypes.Rental ||
      this.unit.unitType == UnitTypes.Event
    );
  }

  private async recalc() {
    this.busy = true;

    if (this.loaded) {
      this.cartItem.nights = Math.abs(
        new Date(this.cartItem.inDateTime).daysDiff(this.cartItem.outDateTime)
      );
      if (this.unitIsTimeSlot()) {
        if (
          this.cartItem.timeSlotGuid == undefined ||
          this.cartItem.rateGuid == undefined
        ) {
          // cannot calculate
          //  this.clearPrice();
          return;
        } else {
        }
      } else {
        if (await this.validate() == false) {
          //this.clearPrice();
        } else {
        }
      }
      await recalcCartItem(this.cartItem).then((ci) => {
        this.cartItem = ci;
        this.cart.items = [];
        this.cart.items.push(this.cartItem);

        setTimeout(async () => {
          this.cartItemKey = Guid.newGuid();
        }, 1000);

      });
    }
    setTimeout(async () => {
      this.cartItemKey = Guid.newGuid();
    }, 1000);
    await this.validate();
    this.busy = false;
  }

  clearPrice() {
    this.cart.items = []
    this.cartItem.price = 0;
  }

  formatCurrency(num: number) {
    if (this.busy || num < 0) {
      return "$--.--";
    }

    return formatter(num, this.property.currencyCode);
  }

  minMaxNightsDisplay() {
    if (this.unit.unitType == UnitTypes.Accommodation) {
      return "";
    }
    if (this.unit.minimumNights == 0) {
      this.unit.minimumNights = 1;
    }
    if (this.unit.maximumNights == 0) {
      this.unit.maximumNights = this.unit.minimumNights;
    }
    //can be guests or nights
    let result = "night";
    if (this.unit.maximumNights === this.unit.minimumNights) {
      result = this.unit.maximumNights + " " + result;
    } else {
      result =
        this.unit.minimumNights +
        " to " +
        this.unit.maximumNights +
        " " +
        result;
    }
    if (this.unit.maximumNights != 1) {
      result += "s";
    }
    return result;
  }

  minMaxPeopleDisplay() {
    if (this.unit.unitType == UnitTypes.Accommodation) {
      return "";
    }
    if (this.unit.minimumPeople == 0) {
      this.unit.minimumPeople = 1;
    }
    if (this.unit.capacity == 0) {
      this.unit.capacity = this.unit.minimumPeople;
    }
    //can be guests or nights
    let result = "people";
    if (this.unit.capacity == 1) {
      result = "person";
    }
    if (this.unit.capacity === this.unit.minimumPeople) {
      result = this.unit.capacity + " " + result;
    } else {
      result =
        this.unit.minimumPeople + " to " + this.unit.capacity + " " + result;
    }
    return result;
  }

  private async addToCart() {
    this.busy = true;
    if (await this.validate() == false) {
      this.busy = false;
      return;
    }
    await this.addCartItem(this.cartItem);
    this.busy = false;
    this.$router.push({
      name: "Cart",
    });
  }

  async updateCartItem() {
    this.busy = true;
    if (await this.validate() == false) {
      this.busy = false;
      return;
    }
    await this.setCartItem(this.cartItem);
    this.busy = false;
    this.$router.push({
      name: "Cart",
    });
  }

  async validate(): boolean {
    //check for min days and max days (done)
    //check for min people and max people for unit
    //check for zero day
    //check for zero or absent price
    //check for invalid dates or not selected dates
    //check for any booked or arriving days between the start and end
    //check for packages with strict day of week
    this.errors = [];
    if (!this.agreeToTerms) {
      this.errors.push("Please agree to the terms & conditions.");
    }
    if (this.unitIsTimeSlot() && !this.cartItem.timeSlotGuid) {
      this.errors.push("Please select a time slot.");
    }
    if (this.unitIsTimeSlot() && !this.cartItem.rateGuid) {
      this.errors.push("Please select a rate.");
    }
    if (this.cartItem.questionnaire) {
      let anyNotAnswered = false;
      for (const q of this.cartItem.questionnaire) {
        if (
          (q.required && !q.answer) ||
          (q.type == "CheckBox" && q.answer && q.answer.toLowerCase() != "yes")
        ) {
          q.state = false;
          anyNotAnswered = true;
        } else {
          q.state = true;
        }
      }
      if (anyNotAnswered) {
        this.errors.push("Please answer all required questions");
      }
    }

    if (!this.unit) {
      return false;
    }

    if (new Date(this.cartItem.inDateTime).areSameDay(new Date())) {
      this.errors.push(
        "Please contact this business for rentals for a rental within 48 hours."
      );
    }
    if (!this.unitIsTimeSlot()) {
      if (this.cartItem.price === 0 && this.cartItem.dueToday == 0) {
        this.errors.push(
          "These dates are not available. Please contact us for more information."
        );
      }

      if (
        this.unit.maximumNights === this.unit.minimumNights &&
        this.cartItem.nights !== this.unit.maximumNights
      ) {
        this.errors.push(
          `Requires ${this.unit.minimumNights} ${this.unit.minimumNights === 1 ? "night" : "nights"
          }`
        );
      } else if (
        this.unit.maximumNights === this.unit.minimumNights &&
        this.cartItem.nights !== this.unit.maximumNights
      ) {
        this.errors.push(`Requires ${this.unit.maximumNights} nights`);
      } else if (
        this.cartItem.nights > this.unit.maximumNights ||
        this.cartItem.nights < this.unit.minimumNights
      ) {
        this.errors.push(
          `Requires between ${this.unit.minimumNights} to ${this.unit.maximumNights} nights`
        );
      }
      if (
        this.unit.minimumPeople === this.unit.capacity &&
        this.getTotalPeople() !== this.unit.minimumPeople
      ) {
        this.errors.push(
          `Allows ${this.unit.minimumPeople} ${this.unit.capacity > 1 ? "people" : "person"
          }`
        );
      } else if (
        this.getTotalPeople() > this.unit.capacity ||
        this.getTotalPeople() < this.unit.minimumPeople
      ) {
        this.errors.push(
          `Allows ${this.unit.minimumPeople} to ${this.unit.capacity} ${this.unit.capacity > 1 ? "people" : "person"
          }`
        );
      }

      const gridDay = this.getUnitGridDay(this.cartItem.inDateTime);
      const gridEnd = this.getUnitGridDay(this.cartItem.outDateTime);
      if (
        gridDay &&
        gridDay.daysOfWeekArrivalAllowed &&
        gridDay.daysRequired > 0
      ) {
        if (
          getDayOfWeekString(gridDay.daysOfWeekArrivalAllowed) !==
          new Date(this.cartItem.inDateTime).getDayOfWeekName(false) ||
          gridDay.daysRequired !== this.cartItem.nights
        ) {
          this.errors.push(
            `Requires arrival on a ${getDayOfWeekString(
              gridDay.daysOfWeekArrivalAllowed
            )} for  ${gridDay.daysRequired} nights`
          );
        }
      }
      else if (gridDay && gridDay.daysOfWeekArrivalAllowed) {
        let thisDayOfWeekName = new Date(this.cartItem.inDateTime).getDayOfWeekName(false);
        let allowedList = getDayOfWeekString(gridDay.daysOfWeekArrivalAllowed);
        if (allowedList.indexOf(thisDayOfWeekName) == -1) {
          this.errors.push(
            `${thisDayOfWeekName} check in is not allowed.`
          );
        }
      }

      for (let i = 0, len = this.cartItem.nights; i < len; i++) {
        const gridDay = this.getUnitGridDay(
          new Date(this.cartItem.inDateTime).addDays(i)
        );
        if (
          gridDay &&
          !(
            gridDay.booked ||
            gridDay.arriving ||
            gridDay.isInPast ||
            gridDay.isToday
          )
        ) {
          //it's open and good
        } else {
          // this is invalid
          const errorDesc =
            "Invalid date selection. Please choose dates that are available in the calendar.";
          if (!this.errors.some((e) => e === errorDesc)) {
            this.errors.push(errorDesc);
          }
        }
      }
    }

    if (this.loaded == true && this.cartItem.price == 0) {
      this.errors.push("");
    }
    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  //legacy
  getUnitGridDay(dateToFind: Date) {
    if (this.unit.gridDays) {
      return this.unit.gridDays.find((d) =>
        new Date(d.dateFor)
          .removeTime()
          .areSameDay(new Date(dateToFind).removeTime())
      );
    }
  }

  getTotalPeople() {
    return this.cartItem.adults + this.cartItem.children;
  }

  getDefaultAvailParams() {
    if (
      !this.availParams ||
      !this.availParams.arrivalDate ||
      !this.availParams.adults
    ) {
      const paramsAvail = new AvailabilityParameters();
      paramsAvail.propertyGuid = this.property.propertyGuid;
      this.set(paramsAvail);
    }
  }

  @cartStore.Action
  public addCartItem!: (cartItem: CartItem) => Promise<boolean>;

  @cartStore.Action
  public setCartItem!: (cartItem: CartItem) => Promise<boolean>;

  @cartStore.Action
  public getCartItem!: (cartItemGuid: string) => Promise<CartItem>;

  @AvailabilityParametersStore.Action
  public set!: (params: AvailabilityParameters) => Promise<boolean>;

  @AvailabilityParametersStore.State
  private availParams!: AvailabilityParameters;
}
export default Book;
